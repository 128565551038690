import React from 'react';
import { graphql } from 'gatsby';
import styled from 'styled-components';
import useBuildPageComponents from 'utils/useBuildPageComponents';
import ToolboxLayout from '../layouts/toolboxLayout';
import toolboxLogos from '../components/Page/Toolbox/data/toolboxLogos';

const PodcastSplashPage = (props) => {
  const { podcastLogo } = toolboxLogos();

  const breadcrumbs = {
    data: [
      {
        title: 'Toolbox',
        icon: null,
        url: '/toolbox',
      },
      {
        title: 'Podcasts',
        icon: podcastLogo,
        url: '',
        hideInMobile: true,
      },
    ],
    absolute: true,
    transparent: true,
    theme: 'light',
  };

  return (
    <Container>
      <ToolboxLayout
        breadcrumbs={breadcrumbs}
        layout="toolbox"
        theme="TRANS_BG_WHITE_COLOR"
        contentfulSeo={props.data.contentfulPage.seo}
        {...props}
      >
        {useBuildPageComponents(props)}
      </ToolboxLayout>
    </Container>
  );
};

export default PodcastSplashPage;

export const PodcastSplashPageQuery = graphql`
  query PodcastSplashPageQuery($pageUrl: String!) {
    contentfulPage(seo: { pageUrl: { eq: $pageUrl } }) {
      ...Page
    }
  }
`;

const Container = styled.div`
  .website-header .website-header--main-menu {
    background: transparent;
  }

  .website-header.scrolled .website-header--main-menu {
    background: var(--white);
  }

  .main-container {
    padding: 0;
  }

  .hero .section-container-row {
    padding: 350px 28px 75px 28px !important;

    @media (max-width: 1199px) {
      padding: 265px 28px 75px 28px !important;
    }

    @media (max-width: 767px) {
      padding: 250px 28px 50px 28px !important;
    }
  }
`;
